<template>
  <div style="height: inherit">

    <h1 class="visually-hidden">Actualités - PharmaConnect</h1>

    <b-overlay :show="showLoading" spinner-variant="primary">

      <b-modal hide-footer ref="item-modal" title="Détails de l'article" size="xl" scrollable>

        <ProductDetailsPARA v-if="selected.type == 'PARA'" :product="selected" />
        <ProductDetailsMED v-else :product="selected" />

      </b-modal>

      <!-- ECommerce Header -->
      <section id="ecommerce-header">
        <!-- <div class="row">
          <div class="col-sm-12">
            <div class="ecommerce-header-items">
              <div class="result-toggler">
                <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="21"
                  @click="mqShallShowLeftSidebar = true" />

              </div>
            </div>
          </div>
        </div> -->
        <b-row>
          <b-col cols="2">
            <div class="ecommerce-header-items">
              <div class="result-toggler">
                <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="28"
                  @click="mqShallShowLeftSidebar = true" />
              </div>
            </div>
          </b-col>
          <b-col>
            <h2 class="d-block d-lg-none"> {{ pageTitle }}</h2>
          </b-col>

        </b-row>
      </section>


      <b-modal centered size="sm" id="modal-prevent-closing" ref="add-to-cart-modal" :title="product.Description"
        hide-footer>
        <b-form-input placeholder="La quantité demandée" ref="qte-input" v-model="requestedQte" type="number"
          @keyup.enter="handleOk"></b-form-input>
        <div class="d-block text-center">
          <b-button variant="relief-success" class="mt-1" @click="handleOk">Valider</b-button>
        </div>
      </b-modal>

      <div id="products-list">
        <b-card>
          <ag-grid @cellDoubleClicked="showDetails"
            v-if="filters.pages == 'Promotions' || filters.pages == 'Affaire du jour'"
            :agGrid="{ ...agGrid, columnDefs: promotionsColumnDefs }" :pageTitle="isMobileDevice() ? '' : pageTitle"
            @refresh="loadData" ref="agGrid" />
          <ag-grid v-else-if="filters.pages == 'Répartitions'" @cellDoubleClicked="showDetails"
            :agGrid="{ ...agGrid, columnDefs: distributionsColumnDefs }" :pageTitle="isMobileDevice() ? '' : pageTitle"
            @refresh="loadData" ref="agGrid" />
          <ag-grid @cellDoubleClicked="showDetails" v-else :agGrid="{ ...agGrid, columnDefs: columnDefs }"
            :pageTitle="isMobileDevice() ? '' : pageTitle" @refresh="loadData" ref="agGrid" />
        </b-card>
      </div>

      <!-- Sidebar -->
      <portal to="content-renderer-sidebar-detached-left">
        <shop-left-filter-sidebar :filters="filters" :filter-options="filterOptions" :showLoading="showLoading"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar" @triggerFilter="handleEventFilter"
          @pageChanged="pageChanged" />
      </portal>

    </b-overlay>
  </div>
</template>

<script>
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ShopLeftFilterSidebar from './ActualitiesShopLeftFilterSidebar.vue'
import axios from "@/libs/axios";
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import BadgeCellRenderer from "@/views/components/ag-grid/BadgeCellRenderer.vue";
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatPrice } from "@/utils/formatting";
import BadgeCellRendererPromotion from "@/views/components/ag-grid/BadgeCellRendererPromotion.vue";
import ProductDetailsMED from "@/views/product/ProductDetailsMED.vue";
import ProductDetailsPARA from "@/views/product/ProductDetailsPARA.vue";

export default {

  metaInfo() {
    return {
        title: "Actualités - PharmaConnect",
        meta: [
            {
                vmid: "description",
                name: "description",
                content:
                    "Découvrez toutes les actualités sur PharmaConnect, incluant les arrivages récents, les nouveautés, les promotions et les répartitions.",
            },
            {
                name: "robots",
                content: "index, follow",
            },
            {
                property: "og:title",
                content: "Actualités - PharmaConnect",
            },
            {
                property: "og:description",
                content: "Consultez les actualités de PharmaConnect, telles que les nouveaux produits, les promotions et les arrivages. Restez informé des dernières offres et mises à jour.",
            },
            {
                property: "og:image",
                content: "https://www.pharmaconnect.net/images/actuality-thumbnail.jpg",
            },
            {
                property: "og:url",
                content: "https://www.pharmaconnect.net/actuality",
            },
            {
                property: "og:type",
                content: "website",
            },
            {
                name: "twitter:card",
                content: "summary_large_image",
            },
            {
                name: "twitter:title",
                content: "Actualités - PharmaConnect",
            },
            {
                name: "twitter:description",
                content: "Découvrez toutes les actualités sur PharmaConnect, y compris les promotions, les nouveautés, les arrivages et les répartitions.",
            },
            {
                name: "twitter:image",
                content: "https://www.pharmaconnect.net/images/actuality-thumbnail.jpg",
            },
        ],
        link: [
            {
                rel: "canonical",
                href: "https://www.pharmaconnect.net/actuality",
            },
        ],
        script: [
            // Structured Data using Schema.org
            {
                type: "application/ld+json",
                json: {
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": "Actualités - PharmaConnect",
                    "description": "Découvrez toutes les actualités sur PharmaConnect, incluant les arrivages récents, les nouveautés, les promotions et les répartitions. Restez informé des dernières mises à jour du secteur pharmaceutique.",
                    "url": "https://www.pharmaconnect.net/actuality"
                },
            },
        ],
    };
},

  components: {
    AgGrid,
    // SFC
    ShopLeftFilterSidebar,
    ProductDetailsMED,
    ProductDetailsPARA
  },
  data() {
    return {
      selected: {},
      product: {
        Description: ""
      },
      requestedQte: '',
      columnDefs: [
        {
          headerName: "Description",
          field: "Description",
        },
        {
          headerName: "Date",
          field: "Posting_Date",
          width: this.isMobileDevice() ? 1 : 100
        },
        {
          headerName: "Fournisseur",
          field: "Vendor_Name",
        },
        {
          headerName: "Prix H.T",
          field: "Unit_Price",
          valueGetter: function (tr) {
            return formatPrice(tr.data.Unit_Price);
          },
          cellClass: 'right-customeCellRender',
          headerClass: 'right-customeHeaderRender',
          width: this.isMobileDevice() ? 100 : 70
        },
        {
          headerName: "Famille",
          field: "Gen_Prod_Posting_Group",
          cellRendererFramework: 'badgeCellRenderer',
          cellRendererParams: { componentName: "Actuality", columnName: "Family" },
          width: this.isMobileDevice() ? 140 : 90
        },
        {
          sortable: false,
          resizable: false,
          filter: false,
          cellClass: 'centered-customeCellRender',
          cellRendererFramework: 'buttonCellRenderer',
          cellRendererParams: { componentName: "Actuality", onButtonClick: this.handleCartActionClick, icon: "shopping_cart", variant: "outline-success" },
          headerName: '',
          width: this.isMobileDevice() ? 90 : 50
        },
        {
          sortable: false,
          resizable: false,
          filter: false,
          cellClass: 'centered-customeCellRender',
          cellRendererFramework: 'buttonCellRenderer',
          cellRendererParams: { componentName: "Actuality", onButtonClick: this.showDetails, icon: "more" },
          headerName: '',
          width: this.isMobileDevice() ? 90 : 50
        },
      ],
      promotionsColumnDefs: [
        // {
        //   headerName: "N°",
        //   field: "No",
        //   width: 100
        // },
        {
          headerName: "Description",
          field: "Description",
        },
        {
          headerName: "Fournisseur",
          field: "Vendor_Name",
        },
        {
          headerName: "Prix H.T",
          field: "Unit_Price",
          valueGetter: function (tr) {
            return formatPrice(tr.data.Unit_Price);
          },
          cellClass: 'right-customeCellRender',
          headerClass: 'right-customeHeaderRender',
          width: this.isMobileDevice() ? 100 : 80
        },
        {
          headerName: "Famille",
          field: "Gen_Prod_Posting_Group",
          cellRendererFramework: 'badgeCellRenderer',
          cellRendererParams: { componentName: "Actuality", columnName: "Family" },
          width: this.isMobileDevice() ? 110 : 90
        },
        {
          headerName: "Promotion",
          field: "Sales_threshold",
          cellRendererFramework: 'badgeCellRendererPromotion',
          width: this.isMobileDevice() ? 110 : 90,
        },
        {
          sortable: false,
          resizable: false,
          filter: false,
          cellClass: 'centered-customeCellRender',
          cellRendererFramework: 'buttonCellRenderer',
          cellRendererParams: { componentName: "Actuality", onButtonClick: this.handleCartActionClick, icon: "shopping_cart", variant: "outline-success" },
          headerName: '',
          width: this.isMobileDevice() ? 90 : 70
        },
        {
          sortable: false,
          resizable: false,
          filter: false,
          cellClass: 'centered-customeCellRender',
          cellRendererFramework: 'buttonCellRenderer',
          cellRendererParams: { componentName: "Actuality", onButtonClick: this.showDetails, icon: "more" },
          headerName: '',
          width: this.isMobileDevice() ? 90 : 70
        },
      ],
      distributionsColumnDefs: [
        // {
        //   headerName: "N°",
        //   field: "No",
        //   width: 100
        // },
        {
          headerName: "Description",
          field: "Description",
        },
        {
          headerName: "Fournisseur",
          field: "Vendor_Name",
        },
        {
          headerName: "Prix H.T",
          field: "Unit_Price",
          valueGetter: function (tr) {
            return formatPrice(tr.data.Unit_Price);
          },
          cellClass: 'right-customeCellRender',
          headerClass: 'right-customeHeaderRender',
          width: this.isMobileDevice() ? 100 : 80
        },
        {
          headerName: "Famille",
          field: "Gen_Prod_Posting_Group",
          cellRendererFramework: 'badgeCellRenderer',
          cellRendererParams: { componentName: "Actuality", columnName: "Family" },
          width: this.isMobileDevice() ? 120 : 90
        },
        {
          headerName: "Quota",
          field: "Open_Quantity",
          width: this.isMobileDevice() ? 120 : 100,
        },
        {
          sortable: false,
          resizable: false,
          filter: false,
          cellClass: 'centered-customeCellRender',
          cellRendererFramework: 'buttonCellRenderer',
          cellRendererParams: { componentName: "Actuality", onButtonClick: this.handleCartActionClick, icon: "shopping_cart", variant: "outline-success" },
          headerName: '',
          width: this.isMobileDevice() ? 100 : 70
        },
        {
          sortable: false,
          resizable: false,
          filter: false,
          cellClass: 'centered-customeCellRender',
          cellRendererFramework: 'buttonCellRenderer',
          cellRendererParams: { componentName: "Actuality", onButtonClick: this.showDetails, icon: "more" },
          headerName: '',
          width: this.isMobileDevice() ? 100 : 70
        },
      ],
      agGrid: {
        rows: []
      },
      pageTitle: "Répartitions",
      uri: 'distribution',
      showLoading: true,
      allProductsWithoutFilters: [],
      filters: {
        q: '',
        priceRangeDefined: 'Tous',
        categories: 'Tous',
        pages: "Répartitions",
        vendors: 'Tous',
        ratings: 1,
      },
      filterOptions: {
        priceRangeDefined: [
          { text: 'Tous', value: 'Tous' },
          { text: '<= 10 TND', value: '<=10' },
          { text: '10 TND - 30 TND', value: '10-30' },
          { text: '30 TND - 50 TND', value: '30-50' },
          { text: '>= 50 TND', value: '>=50' },
        ],
        categories: [
        ],
        pages: [
          "Affaire du jour",
          "Arrivages",
          "Nouveautés",
          "Promotions",
          "Répartitions"
        ],
        vendors: [],
        ratings: [
          { rating: 1, value: 1 },
          { rating: 3, value: 3 },
          { rating: 4, value: 4 },
        ],
      },
    }
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    return {
      mqShallShowLeftSidebar,
    }
  },
  methods: {
    isMobileDevice() {
      return window.innerWidth <= 769;
    },
    handleCartActionClick(data) {
      this.product = data;
      this.requestedQte = "";
      this.$refs['add-to-cart-modal'].show();
      setTimeout(() => {
        this.$refs["qte-input"].focus();
      }, 400);
    },
    async handleOk() {
      this.$refs['add-to-cart-modal'].hide();
      this.showLoading = true;
      let resp = await this.$http.get("_item/getItemCodeByItemNo/" + this.product.No);
      if (resp.data != "") {
        let itemToAdd = this.$store.getters["app-items/geItemByCode"](resp.data);
        itemToAdd["Qte"] = this.requestedQte;
        this.$store.dispatch('app-items/GET_INVENTORY', itemToAdd).then((response) => {
          this.$toast.clear();
          this.showLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: response,
          },
            {
              timeout: 2000
            });
        }).catch((error) => {
          this.$toast.clear();
          if (error.title == "Article Actuellement Indisponible") {
            this.$toast({
              component: ToastificationContent,
              props: error,
            },
              {
                position: 'top-left',
              }
            );
          } else {
            this.$toast({
              component: ToastificationContent,
              props: error,
            });
          }
          this.showLoading = false;
        });
      } else {
        this.$swal({
          title: "Article Actuellement Indisponible",
          text: "Désolé, cet article n'existe pas encore dans Pharmaconnect.",
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
        this.showLoading = false;
      }
    },
    async showDetails(data) {
      const no = data.No == undefined ? data.data.No : data.No;
      let resp = await this.$http.get("_item/getItemCodeByItemNo/" + no);
      if (resp.data != "") {
        let itemToAdd = this.$store.getters["app-items/geItemByCode"](resp.data);
        this.selected = itemToAdd;
        this.$refs['item-modal'].show();
      } else {
        this.$swal({
          title: "Article Actuellement Indisponible",
          text: "Désolé, cet article n'a pas encore été créé chez Pharmaconnect.",
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      this.showLoadingModal = false;
    },
    async pageChanged(page) {
      this.mqShallShowLeftSidebar = false;
      switch (page) {
        case "Arrivages":
          this.uri = 'arrival';
          this.pageTitle = "Liste des arrivages";
          this.loadData();
          return;
        case "Nouveautés":
          this.uri = 'novelty';
          this.pageTitle = "Liste des nouveautés";
          this.loadData();
          return;
        case "Promotions":
          this.uri = 'promotion';
          this.pageTitle = "Liste des promotions";
          this.loadData();
          return;
        case "Affaire du jour":
          this.uri = 'promotion';
          this.pageTitle = "Affaire du jour";
          this.loadData();
          return;
        case "Répartitions":
          this.uri = 'distribution';
          this.pageTitle = "Liste des répartitions";
          this.loadData();
          return;
      }
    },
    async handleEventFilter(data) {
      this.mqShallShowLeftSidebar = false;
      // Method to be executed when the custom event is emitted from the child component
      this.filters.q = "";
      this.showLoading = true;
      // console.log('Custom event emitted from child component');
      this.agGrid.rows = this.allProductsWithoutFilters;
      await this.filterByCategory(data);
      await this.filterByVendor(data);
      await this.filterByPrice(data);
      this.showLoading = false;
    },
    async filterByCategory(data) {
      this.mqShallShowLeftSidebar = false;
      if (data.categories != "Tous")
        this.agGrid.rows = this.agGrid.rows.filter(item => item.Gen_Prod_Posting_Group === data.categories);
    },
    async filterByVendor(data) {
      this.mqShallShowLeftSidebar = false;
      if (data.vendors != "Tous")
        this.agGrid.rows = this.agGrid.rows.filter(item => item.Vendor_Name === data.vendors);
    },
    async filterByPrice(data) {
      this.mqShallShowLeftSidebar = false;
      if (data.rating != "Tous") {
        switch (data.priceRangeDefined) {
          case '<=10':
            this.agGrid.rows = this.agGrid.rows.filter(item => item.Unit_Price <= 10);
            break;
          case '10-30':
            this.agGrid.rows = this.agGrid.rows.filter(item => item.Unit_Price >= 10 && item.Unit_Price <= 30);
            break;
          case '30-50':
            this.agGrid.rows = this.agGrid.rows.filter(item => item.Unit_Price >= 30 && item.Unit_Price <= 50);
            break;
          case '>=50':
            this.agGrid.rows = this.agGrid.rows.filter(item => item.Unit_Price >= 50);
            break;
        }
      }
    },
    async loadData() {
      try {
        this.showLoading = true;
        let url = this.uri;
        if (this.filters.pages == "Affaire du jour") {
          url = url + "/findWhere?affaire_du_jour=true";
        }
        // console.log(url);
        const resp = await axios.get(url);
        // console.log(resp);
        // console.log(resp);
        this.agGrid.rows = resp.data
        if (this.filters.pages == "Promotions" || this.filters.pages == "Affaire du jour")
          this.agGrid.rows = this.agGrid.rows.filter(item => item.Inventory >= (item.Free_Sales_Qty + item.Sales_threshold));
        else
          this.agGrid.rows = resp.data;
        // if (this.filters.pages != "Répartitions")
        //   this.agGrid.rows = this.agGrid.rows.filter(item => item.Location_Code == JSON.parse(localStorage.getItem('userInfo')).locationCode);
        this.allProductsWithoutFilters = resp.data;
        await this.setCategories();
        await this.setVendors();

        this.filters.priceRangeDefined = 'Tous';
        this.filters.categories = 'Tous';
        this.filters.vendors = 'Tous';
        this.filters.ratings = 1;

        this.showLoading = false;
        if (!/Mobi/i.test(navigator.userAgent))
          this.$refs.agGrid.gridOptions.api.sizeColumnsToFit();
      } catch (error) {
        console.error('Error loading data:', error);
      }
    },
    async setCategories() {
      try {
        const distinctCategories = [...new Set(this.agGrid.rows.map(product => product.Gen_Prod_Posting_Group))];
        distinctCategories.unshift("Tous");
        this.filterOptions.categories = distinctCategories;
        // console.log(distinctCategories);
      } catch (error) {
        console.error('Error retrieving categories:', error);
      }
    },
    async setVendors() {
      try {
        const distinctVendoros = [...new Set(this.agGrid.rows.map(product => product.Vendor_Name))];
        distinctVendoros.unshift("Tous");
        this.filterOptions.vendors = distinctVendoros;
        // console.log(distinctVendoros);
      } catch (error) {
        console.error('Error retrieving vendors:', error);
      }
    },
  },
  async created() {
    this.$options.components.buttonCellRenderer = ButtonCellRenderer;
    this.$options.components.badgeCellRenderer = BadgeCellRenderer;
    this.$options.components.badgeCellRendererPromotion = BadgeCellRendererPromotion;
    await this.loadData();
  }
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .card {
    padding: 0px;
    background-color: initial;
    box-shadow: initial;
  }

  .card-body {
    /* Display the card on screens wider than 768px */
    // display: block;
    padding: 0px;
  }
}
</style>
