<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div class="sidebar-shop" :class="{ 'show': mqShallShowLeftSidebar }">
        <!-- Filters' Card -->
        <b-card>

          <!-- pages -->
          <div class="filter-title  mt-1 top-filter">
            <h6>
              Pages
            </h6>
            <b-form-radio-group v-model="localFilters.pages" class="categories-radio-group" stacked :disabled="showLoading"
              :options="filterOptions.pages" @change="$emit('pageChanged', localFilters.pages)" />
          </div>

          <!-- Categories -->
          <div class="filter-title mt-2">
            <h6>
              Categories
            </h6>
            <b-form-radio-group v-model="localFilters.categories" class="categories-radio-group" stacked
              :disabled="showLoading" :options="filterOptions.categories" @change="triggerFilter" />
          </div>


          <!-- Multi Range -->
          <div class="filter-title mt-2">
            <h6>
              Prix
            </h6>
            <b-form-radio-group v-model="localFilters.priceRangeDefined" class="price-range-defined-radio-group" stacked
              :disabled="showLoading" :options="filterOptions.priceRangeDefined" @change="triggerFilter" />
          </div>

          <!-- Brands -->
          <h6 class="filter-title mt-2">
            Fournisseur
          </h6>
          <div class="brands" style="overflow-y: scroll; max-height: 300px;">
            <b-form-radio-group v-model="localFilters.vendors" class="brands-radio-group" stacked :disabled="showLoading"
              :options="filterOptions.vendors" @change="triggerFilter" />
          </div>

        </b-card>
      </div>
    </div>

    <div class="body-content-overlay" :class="{ 'show': mqShallShowLeftSidebar }"
      @click="$emit('update:mq-shall-show-left-sidebar', false)" />
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
    showLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localFilters: { ...this.filters }
    }
  },
  methods: {
    triggerFilter() {
      this.$emit('triggerFilter', this.localFilters);
    },
    ratingClick(data) {
      this.localFilters.ratings = data.rating
      this.triggerFilter();
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep>.custom-control {
    margin-bottom: 0.75rem;
  }
}

@media (max-width: 769px) {
  .top-filter {
    padding-top: 3rem;
    /* Adjust the margin-top value as needed */
  }
}
</style>
