<template>
    <div id="badge-component">
        <b-badge variant="success">
            <span>{{ params.data.Sales_threshold + " -> " + params.data.Free_Sales_Qty }}</span>
        </b-badge>
    </div>
</template>
  
<script>

export default {
};
</script>